









































import { Component, Watch, Vue } from 'vue-property-decorator'
import sidebarModule from '@/store/sidebar'
import { BButton, BForm } from 'bootstrap-vue'
import { RouteNames } from '@/router'
import { Debounce } from 'vue-debounce-decorator'
const Sidebar = () => import('../views/sidebar.vue')
const Field = () => import('@/components/Field.vue')

@Component({
  components: {
    Field,
    Sidebar,
    BButton,
    BForm
  }
})

export default class Catalog extends Vue {
  search=''

  get isOpenSidebar(): boolean {
    return sidebarModule.state.open
  }

  closeSidebar():void{
    sidebarModule.mutations.setOpen(false)
  }

  searchInStore():void {
    this.$router.push({
      name: RouteNames.store,
      query: {
        search: this.search
      }
    })
  }

  @Watch('search')
  @Debounce(500)
  onSearchChanged(): void {
    if (!this.search.length || this.search.length >= 3) {
      this.searchInStore()
    }
  }
}
